import BadmintonServeLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/badmintonServe.svg';
import BaseballLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/baseball.svg';
import BeachVolleyballLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/beachVolleyball.svg';
import BilliardsLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/billiards.svg';
import CounterStrikeLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/counterStrike.svg';
import CricketLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/cricket.svg';
import DartsLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/darts.svg';
import ValorantLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/gamepad.svg';
import TableTennisLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/tableTennis.svg';
import TennisLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/tennis.svg';
import VolleyballServeLeadIcon from '@public/assets/sportCompetitorLeadSide/icons/volleyballServe.svg';

// NOTE: Ключи для объекта нужно брать из sport.code в запросах для LineSB типа.
export const competitorLeadSideIcons: Record<string, SvgComponent | null> = {
  badminton: BadmintonServeLeadIcon,
  baseball: BaseballLeadIcon,
  'beach-volley': BeachVolleyballLeadIcon,
  snooker: BilliardsLeadIcon,
  'counter-strike': CounterStrikeLeadIcon,
  cricket: CricketLeadIcon,
  darts: DartsLeadIcon,
  'table-tennis': TableTennisLeadIcon,
  tennis: TennisLeadIcon,
  valorant: ValorantLeadIcon,
  volleyball: VolleyballServeLeadIcon,
};
