import type { FC } from 'react';
import { useMemo } from 'react';
import {
  getTestProps,
  omitTestProps,
  selectUserCurrency,
  selectUserIsAuthenticated,
  selectUserLocale,
  useFetch,
} from '@mwl/core-lib';

import { CardGrid } from '@/components/CardGrid/CardGrid';
import { GamesGridLayout } from '@/components/GamesGridLayout/GamesGridLayout';
import { PinnedLine } from '@/components/PinnedLine/PinnedLine';
import { useTypedSelector } from '@/hooks';
import { sportLinesEnrichment } from '@/utils';

import type { TopLinesProps } from './TopLines.types';

export const TopLines: FC<TopLinesProps> = ({ title, href, className, fetchLines, ...props }) => {
  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const currency = useTypedSelector(selectUserCurrency);
  const locale = useTypedSelector(selectUserLocale);

  const { data: linesResponse } = useFetch('pinnedLines', fetchLines, { disabled: !currency || !locale || !isAuth });

  const lines = useMemo(() => (linesResponse ? sportLinesEnrichment(linesResponse) : []), [linesResponse]);

  if (!lines.length) {
    return null;
  }

  return (
    <GamesGridLayout {...getTestProps(props)} title={title} href={href} className={className}>
      <CardGrid {...omitTestProps(props)}>
        {lines.map((line) => (
          <PinnedLine line={line} key={line.id} />
        ))}
      </CardGrid>
    </GamesGridLayout>
  );
};
