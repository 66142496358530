import type { FC } from 'react';
import type { OutcomeButtonBaseClasses, OutcomeButtonBaseProps } from '@mwl/ui';
import { OutcomeButtonBase } from '@mwl/ui';

import styles from './OutcomeButton.module.scss';

const defaultClasses: OutcomeButtonBaseClasses = {
  frozen: styles.frozen,
  empty: styles.empty,
  title: styles.title,
  value: styles.value,
  active: styles.active,
  decrease: styles.decrease,
  increase: styles.increase,
  vertical: styles.vertical,
  horizontal: styles.horizontal,
};

const OutcomeButton: FC<OutcomeButtonBaseProps> = ({ className, classes, ...props }) => {
  return (
    <OutcomeButtonBase
      {...props}
      className={cn(styles.root, className)}
      classes={{
        frozen: cn(defaultClasses.frozen, classes?.frozen),
        empty: cn(defaultClasses.empty, classes?.empty),
        title: cn(defaultClasses.title, classes?.title),
        value: cn(defaultClasses.value, classes?.value),
        active: cn(defaultClasses.active, classes?.active),
        decrease: cn(defaultClasses.decrease, classes?.decrease),
        increase: cn(defaultClasses.increase, classes?.increase),
        vertical: cn(defaultClasses.vertical, classes?.vertical),
        horizontal: cn(defaultClasses.horizontal, classes?.horizontal),
      }}
    />
  );
};

export { OutcomeButton };
