import type { SwiperProps } from 'swiper/react';

export const sliderProps: SwiperProps = {
  navigation: false,
  slidesPerView: 'auto',
  spaceBetween: 8,
  resizeObserver: true,
  grabCursor: true,
  breakpoints: {
    0: {
      slidesPerView: 'auto',
      slidesOffsetAfter: 16,
    },
    1024: {
      slidesPerView: 3,
      slidesOffsetAfter: 0,
    },
    1366: {
      slidesPerView: 4,
    },
  },
};
