import type { FC } from 'react';
import { useTimezoneDate } from '@mwl/core-lib';

import type { PregameStageStatusProps } from './PregameStageStatus.types';

import styles from './PregameStageStatus.module.scss';

const PregameStageStatus: FC<PregameStageStatusProps> = ({
  beginAt,
  dayFormat = 'MM.dd.yy',
  timeFormat = 'hh:mm',
  classes,
  className,
  ...props
}) => {
  const beginAtDate = new Date(beginAt || '');

  const { value: dayValue } = useTimezoneDate({
    date: beginAtDate,
    format: dayFormat,
  });

  const { value: timeValue } = useTimezoneDate({
    date: beginAtDate,
    format: timeFormat,
  });

  if (!beginAt) {
    return null;
  }

  return (
    <div className={cn(styles.root, className)} {...props}>
      <div className={cn(styles.day, classes?.day)}>{dayValue}</div>
      <div className={cn(styles.time, classes?.time)}>{timeValue}</div>
    </div>
  );
};

export { PregameStageStatus };
