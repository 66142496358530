import type { FC } from 'react';

import SportFieldIcon from '@public/assets/sport/icons/field.svg';
import StreamIcon from '@public/assets/sport/icons/stream.svg';

import { LiveStageStatus } from '@/components/LiveStageStatus/LiveStageStatus';
import { PregameStageStatus } from '@/components/PregameStageStatus/PregameStageStatus';
import type { PregameStageStatusClasses } from '@/components/PregameStageStatus/PregameStageStatus.types';
import { SportCompetitorLeadSideIcon } from '@/components/SportCompetitorLeadSideIcon/SportCompetitorLeadSideIcon';

import type { PinnedMatchInfoProps } from './PinnedMatchInfo.types';

import styles from './PinnedMatchInfo.module.scss';

const pregameStatusClasses: PregameStageStatusClasses = {
  time: styles.pregameTime,
};

export const PinnedMatchInfo: FC<PinnedMatchInfoProps> = ({ line, hasWidgets, hasTranslation, singleCompetitor }) => {
  const { status, stage, stats, beginAt } = line;
  const { competitorStats, currentSegment, clock } = stats ?? {};

  const score = competitorStats?.map((item) => item.totalScore).join(':');

  if (stage !== 'LIVE' && stage !== 'PREGAME') {
    return null;
  }

  return (
    <div
      className={cn(styles.root, styles[(stage || '').toLowerCase()], { [styles.isOneCompetitor]: singleCompetitor })}
    >
      <div className={styles.statusWrapper}>
        {stage === 'LIVE' && (
          <LiveStageStatus
            currentSegment={currentSegment}
            clock={clock}
            beginAt={beginAt}
            status={status}
            className={styles.liveStatus}
          />
        )}
        {stage === 'PREGAME' && (
          <PregameStageStatus beginAt={beginAt} classes={pregameStatusClasses} className={styles.pregameStatus} />
        )}
        {score && !singleCompetitor && (
          <div className={styles.score}>
            <SportCompetitorLeadSideIcon side="HOME" line={line} />
            <span>{score}</span>
            <SportCompetitorLeadSideIcon side="AWAY" line={line} />
          </div>
        )}
      </div>
      <div className={styles.icons}>
        {hasWidgets && stage === 'LIVE' && <SportFieldIcon className={styles.icon} />}
        {hasTranslation && <StreamIcon className={styles.icon} />}
      </div>
    </div>
  );
};
