import type { CompetitorTypeSB, LineSB } from '@mwl/core-lib';

import type { LineWithEntities } from '@/types';

export const getCompetitorLeadSide = (line: LineSB | LineWithEntities): CompetitorTypeSB | undefined => {
  const { stats, competitors } = line;

  const leadCompetitor = competitors.find((competitor) => competitor.id === stats?.server);

  return leadCompetitor?.type;
};
