import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { ButtonFavoritesBase } from '@mwl/ui';

import FavoriteIcon from '@public/assets/common/icons/favorites.svg';
import FavoriteBoldIcon from '@public/assets/common/icons/favoritesBold.svg';

import { ButtonIcon } from '@/components/ButtonIcon/ButtonIcon';

import type { ButtonFavoritesProps } from './ButtonFavorites.types';

import styles from './ButtonFavorites.module.scss';

const BaseButtonFavorites: FC<ButtonFavoritesProps> = ({
  className,
  defaultFavorite = false,
  onClick,
  color = 'yellow',
  variant = 'filled',
  value,
  ...props
}) => {
  const handleChange = useCallback(
    (isActive: boolean) => {
      onClick?.(isActive);
    },
    [onClick],
  );

  return (
    <ButtonFavoritesBase
      as="button"
      role="checkbox"
      className={cn(styles.root, className, styles[color])}
      classes={{ active: styles.active }}
      cut={false}
      onChange={handleChange}
      value={value}
      defaultValue={defaultFavorite}
      component={ButtonIcon}
      renderIcon={(isActive) => (
        <>
          {(variant === 'filled' || isActive) && <FavoriteIcon />}
          {variant === 'outlined' && !isActive && <FavoriteBoldIcon />}
        </>
      )}
      {...props}
    />
  );
};

const ButtonFavorites = memo(BaseButtonFavorites);
export { ButtonFavorites };
