import { type FC, useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import type { UseFetchHandlerOptions } from '@mwl/core-lib';
import { getLinesSB, selectUserIsAuthenticated, useLoadTournaments } from '@mwl/core-lib';

import { ColoredButton } from '@/components/ColoredButton/ColoredButton';
import { Promotions } from '@/components/Promotions/Promotions';
import { SliderCardsLoadable } from '@/components/SliderCardsLoadable/SliderCardsLoadable';
import { Tournaments } from '@/components/Tournaments/Tournaments';
import {
  listings,
  mainCardsBreakpoints,
  promotionsAnalytics,
  promotionsBreakpoints,
  providersAnalytics,
  routes,
  sliderCardsAnalytics,
  sportPinnedBreakpoints,
  tournamentsAnalytics,
  tournamentsBreakpoints,
} from '@/constants';
import { useMediaWidthDown, useTypedSelector } from '@/hooks';
import { loadCasinoNewGames, loadCasinoTopGames, loadFavoritesGames } from '@/modules/casino/utils/loadGames';
import { mainPageObject } from '@/utils';

import { Providers } from '../casino/components/Providers/Providers';

import { CasinoBanners } from './components/CasinoBanners/CasinoBanners';
import { TopLines } from './components/TopLines/TopLines';
import type { MainPageProps } from './MainPage.types';

import styles from './MainPage.module.scss';

export const MainPage: FC<MainPageProps> = ({ className }) => {
  const isAuth = useTypedSelector(selectUserIsAuthenticated);

  const isXsScreen = useMediaWidthDown('screenXs');

  const { t } = useTranslation('casino');

  const { tournaments } = useLoadTournaments(true);

  const hasSportAccess = !!process.env.SPORT_ACCESS;

  const fetchLiveLines = useCallback(
    ({ signal }: UseFetchHandlerOptions) => getLinesSB({ pageOffset: 0, pageSize: 10, stage: 'LIVE' }, { signal }),
    [],
  );

  const fetchPregameLines = useCallback(
    ({ signal }: UseFetchHandlerOptions) => getLinesSB({ pageOffset: 0, pageSize: 10, stage: 'PREGAME' }, { signal }),
    [],
  );

  return (
    <div className={cn(styles.root, className)} {...mainPageObject.root.nodeProps}>
      <div className={styles.buttons}>
        <ColoredButton
          color="yellow"
          className={styles.promoButton}
          href={routes.promo.home}
          image="/assets/bonus/images/promoButtonIcon.png"
        >
          {t('common:promo_bonuses', 'Promo and bonuses')}
        </ColoredButton>
      </div>
      {hasSportAccess && (
        <section className={styles.top}>
          <TopLines
            hideHasMore
            rowsInNotInfiniteBlock={1}
            title={t('section.live', 'Top live')}
            href={routes.sport.home}
            breakpoints={sportPinnedBreakpoints}
            fetchLines={fetchLiveLines}
          />
        </section>
      )}

      <CasinoBanners position={isXsScreen ? 'main_banners_sport_mobile' : 'main_banners_sport'} />

      {hasSportAccess && (
        <TopLines
          hideHasMore
          rowsInNotInfiniteBlock={1}
          breakpoints={sportPinnedBreakpoints}
          title={t('section.pregame', 'Top pregames')}
          href={routes.sport.home}
          fetchLines={fetchPregameLines}
        />
      )}

      <SliderCardsLoadable
        {...mainPageObject.topGames.nodeProps}
        maxDisplayItemsCount={20}
        className={styles.section}
        title={t('section.top', 'Top')}
        loadMethod={loadCasinoTopGames}
        breakpoints={mainCardsBreakpoints}
        moreButtonText={t('common:all', 'All')}
        moreButtonHref={{ pathname: routes.casino.category, query: { category: 'slots', tag: 'top' } }}
        analytics={sliderCardsAnalytics}
        listings={listings.topGames}
      />

      <Providers
        className={styles.section}
        title={t('section.providers', 'Top providers')}
        href={routes.providers.home}
        analytics={providersAnalytics}
      />

      <SliderCardsLoadable
        className={styles.section}
        maxDisplayItemsCount={20}
        title={t('section.new', 'New')}
        loadMethod={loadCasinoNewGames}
        breakpoints={mainCardsBreakpoints}
        moreButtonText={t('common:all', 'All')}
        moreButtonHref={{ pathname: routes.casino.category, query: { category: 'slots', tag: 'new' } }}
        analytics={sliderCardsAnalytics}
        listings={listings.newGames}
      />

      <CasinoBanners
        className={styles.subSection}
        position={isXsScreen ? 'main_banners_casino_mobile' : 'main_banners_casino'}
      />

      {isAuth && (
        <SliderCardsLoadable
          maxDisplayItemsCount={20}
          className={styles.section}
          title={t('section.favorites', 'Top favorites')}
          loadMethod={loadFavoritesGames}
          moreButtonText={t('common:all', 'All')}
          moreButtonHref={routes.casino.favorites}
          breakpoints={mainCardsBreakpoints}
          analytics={sliderCardsAnalytics}
          listings={listings.favorites}
        />
      )}

      <Promotions
        className={styles.section}
        maxDisplayItemsCount={8}
        title={t('section.promotions', 'Promotions')}
        breakpoints={promotionsBreakpoints}
        moreButtonText={t('common:all', 'All')}
        moreButtonHref={{ pathname: routes.promo.home }}
        analytics={promotionsAnalytics}
        listings={listings.promotions}
      />

      <Tournaments
        className={styles.section}
        maxDisplayItemsCount={8}
        title={t('section.tournaments', 'Tournaments')}
        breakpoints={tournamentsBreakpoints}
        moreButtonText={t('common:all', 'All')}
        moreButtonHref={{ pathname: routes.tournaments.home }}
        tournaments={tournaments}
        analytics={tournamentsAnalytics}
        listings={listings.tournaments}
      />
    </div>
  );
};
