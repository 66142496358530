import { type FC, type MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import type { TopMarketOutcomeEmptySB, TopMarketOutcomeSB } from '@mwl/core-lib';
import {
  selectSportFavoritesSetV2,
  selectUserIsAuthenticated,
  useLineBehaviorV2,
  useWebsocketLineShort,
} from '@mwl/core-lib';
import type { OutcomeButtonBaseClasses } from '@mwl/ui';

import { Button } from '@/components/Button/Button';
import { base, routes } from '@/constants';
import { useSportLinks, useTypedSelector } from '@/hooks';

import type { LineWithEntities } from '@/types';

import { MatchTeam } from '../MatchTeam/MatchTeam';
import { OutcomeButton } from '../OutcomeButton/OutcomeButton';
import { SportButtonFavorites } from '../SportButtonFavorites/SportButtonFavorites';
import { SportIcon } from '../SportIcon/SportIcon';

import { PinnedMatchInfo } from './components/PinnedMatchInfo/PinnedMatchInfo';
import type { PinnedLineProps } from './PinnedLine.types';

import styles from './PinnedLine.module.scss';

const outcomeAliases = ['1', 'x', '2'];

const oddClasses: OutcomeButtonBaseClasses = {
  empty: styles.oddEmpty,
  active: styles.oddActive,
  increase: styles.oddIncrease,
  decrease: styles.oddDecrease,
};

export const PinnedLine: FC<PinnedLineProps> = ({ line: initialLine, className }) => {
  const { t } = useTranslation('sport');

  const isAuth = useTypedSelector(selectUserIsAuthenticated);
  const favorites = useTypedSelector(selectSportFavoritesSetV2);

  const line = useWebsocketLineShort<LineWithEntities>(initialLine, base.NAME_PROJECT);

  const { lineHref, hasWidgets, competitors, hasTranslation, singleCompetitor } = useLineBehaviorV2({
    line,
    routes,
  });

  const { SportLink, championshipLink, sportCode, sportIcon } = useSportLinks({ data: initialLine, styles });

  const isFavorite = useMemo(() => favorites.lineIds.has(line.id), [favorites.lineIds, line.id]);

  const market = line.topMarkets?.find((item) => item.alias === '1x2');

  const aliasedOutcomes = useMemo<Array<TopMarketOutcomeSB | TopMarketOutcomeEmptySB>>(() => {
    if (!market) {
      return [];
    }

    const outcomesByAlias = (market?.outcomes ?? []).reduce<Dictionary<TopMarketOutcomeSB>>((acc, outcome) => {
      if (outcome.alias) {
        // eslint-disable-next-line no-param-reassign
        acc[outcome.alias] = outcome;
      }

      return acc;
    }, {});

    return outcomeAliases.map((alias, index) => {
      const outcome = outcomesByAlias[alias];

      if (!outcome) {
        return {
          id: `${market.alias}_${index}`,
          title: t(`outcome_title.${market.alias}_${alias}`),
          alias: 'empty',
        };
      }

      return {
        ...outcome,
        title: t(`outcome_title.${market.alias}_${alias}`),
      };
    });
  }, [market, t]);

  const preventDefault = useCallback((event: MouseEvent) => event.preventDefault(), []);

  return (
    <div className={cn(styles.root, className, styles[line.stage.toLowerCase()])}>
      <div
        className={cn(styles.row, styles.header, {
          [styles.withFavorite]: isAuth,
        })}
      >
        <SportLink>
          <div className={styles.headerIconWrapper}>
            <SportIcon url={sportIcon} size={20} />
          </div>
        </SportLink>
        <p className={styles.headerTitle}>{championshipLink}</p>
        {isAuth && (
          <div onClick={preventDefault} role="presentation">
            <SportButtonFavorites isFavorite={isFavorite} lineId={line.id} />
          </div>
        )}
      </div>

      <div className={cn(styles.row, styles.contentRow)}>
        <Button
          as="link"
          href={lineHref}
          variant="text"
          className={cn(styles.content, { [styles.isOneCompetitor]: !!singleCompetitor })}
          fullWidth
          uppercase={false}
        >
          <MatchTeam
            {...(singleCompetitor ?? competitors.HOME)}
            sportCode={sportCode}
            sportIcon={sportIcon}
            className={styles.team}
            classes={{ name: styles.teamName, nameContainer: styles.teamNameContainer }}
            direction={singleCompetitor ? 'row' : 'column'}
          />

          <PinnedMatchInfo
            line={line}
            hasWidgets={hasWidgets}
            hasTranslation={hasTranslation}
            singleCompetitor={!!singleCompetitor}
          />
          {!singleCompetitor && (
            <MatchTeam
              {...competitors.AWAY}
              sportCode={sportCode}
              sportIcon={sportIcon}
              className={styles.team}
              classes={{ name: styles.teamName, image: styles.teamImage }}
            />
          )}
        </Button>
      </div>

      <div className={cn(styles.row, styles.outcomes)}>
        {aliasedOutcomes.length === 0 && (
          <div className={styles.moreBetsWrapper}>
            <Button
              as="link"
              href={lineHref}
              variant="fulfilled"
              fullWidth
              color="grey"
              fontWeight="bold"
              rounded
              cut={false}
              className={styles.moreBets}
            >
              {t('more_bets', 'More Bets')}
            </Button>
          </div>
        )}
        {aliasedOutcomes.length > 0 && (
          <div className={styles.outcomesWrapper}>
            {aliasedOutcomes.map((outcome) => (
              <OutcomeButton
                lineId={line.id}
                key={outcome.id}
                outcome={outcome as TopMarketOutcomeSB}
                variant="horizontal"
                className={styles.outcome}
                classes={oddClasses}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
