import type { SwiperProps } from 'swiper/react';

export const sliderParams: SwiperProps = {
  updateOnWindowResize: true,
  observer: true,
  observeParents: true,
  allowTouchMove: true,
  pagination: { clickable: false },
  spaceBetween: 8,
  breakpoints: {
    0: {
      slidesPerView: 2,
      slidesOffsetAfter: 16,
    },
    360: {
      slidesPerView: 'auto',
      slidesOffsetAfter: 16,
    },
    768: {
      slidesPerView: 4,
      slidesOffsetAfter: 16,
    },
    850: {
      slidesPerView: 3,
      slidesOffsetAfter: 0,
    },
    1024: {
      slidesPerView: 4,
      slidesOffsetAfter: 0,
    },
    1600: {
      slidesPerView: 6,
      slidesOffsetAfter: 0,
    },
  },
};
