import type { FC } from 'react';
import { getTestProps } from '@mwl/core-lib';

import { Image } from '@/components/Image/Image';

import { SportIcon } from '../SportIcon/SportIcon';

import type { MatchTeamProps } from './MatchTeam.types';

import styles from './MatchTeam.module.scss';

export const MatchTeam: FC<MatchTeamProps> = ({
  classes,
  sportCode,
  sportIcon,
  className,
  direction = 'column',
  image,
  title,
  ...props
}) => {
  const dataCyProps = getTestProps(props);

  return (
    <div className={cn(styles.root, styles[direction], className)} {...dataCyProps}>
      <div className={cn(styles.image, classes?.image)}>
        {image && <Image alt={title || sportCode} src={image} fill style={{ objectFit: 'contain' }} />}
        {!image && <SportIcon className={styles.unknownLogo} url={sportIcon ?? ''} size={20} />}
      </div>
      <div className={cn(styles.nameContainer, classes?.nameContainer)}>
        <span className={cn(styles.name, classes?.name)} title={title}>
          {title}
        </span>
      </div>
    </div>
  );
};

export * from './MatchTeam.types';
